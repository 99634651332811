<template>
  <div class="maxHeight">
    <div class="header">
      <div class="title flex">
        <img src="../../assets/img/user.png" alt="" width="28">
        <h2>部门管理</h2>
      </div>
    </div>
    <div class="contentBody">
      <div class="flex tableHead">
        <el-button size="small" @click="add">新增部门</el-button>
      </div>
      <el-table
          border row-key="id" :tree-props="{children: 'children', hasChildren: true}"
          :data="tableData" :default-sort="{prop: 'date', order: 'descending'}"
          class="record-table" default-expand-all
          :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
          height="calc(100vh - 280px)">
        <el-table-column prop="title" label="部门名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="order" label="排序" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.createTime | formatDay }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="modifyTime" label="修改时间" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.modifyTime | formatDay }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row.parentId, scope.row)" type="text" size="small">编辑</el-button>
            <el-button type="text" size="small" @click="handleDelete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog  :close-on-click-modal="false"
        :title="action+'部门'"
        class="flex dialogForm "
        top="0"
        :visible.sync="dialogFormVisible"
        width="400px">
      <el-form ref="formAction" size="small" :model="formAction" class="addForm" label-suffix=":" :rules="ruleInline"
               label-width="90px">
        <el-form-item label="部门名称" prop="name">
          <el-input v-model="formAction.name" clearable autocomplete="off" placeholder="请输入部门名称"></el-input>
        </el-form-item>
        <el-form-item label="部门排序" prop="orderNum">
          <el-input placeholder="请输入部门排序" clearable v-model="formAction.orderNum">
          </el-input>
        </el-form-item>
        <el-form-item label="上级部门" class="permission">
          <el-tree :data="permission" accordion @check-change="handleClick" ref="treeForm" @node-click="nodeClick"
                   :default-checked-keys="stdNode" default-expand-all
                   show-checkbox node-key="id" :check-strictly="true" :props="defaultProps">
          </el-tree>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="submit('formAction')">确认</el-button>
          <el-button size="small" type="primary" plain @click="dialogFormVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>

</template>

<script>
export default {
  name: "depart",
  data() {
    return {
      tableData: [],
      permission: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      formAction: {
        name: '',
        orderNum: '',
      },
      dialogFormVisible: false,
      stdNode: [],
      action: '新增',
      isEdit: false,
      ruleInline: {
        name: [
          {required: true, message: '名称不能为空', trigger: 'blur'}
        ],
        orderNum: [
          {required: true, message: '排序不能为空', trigger: 'blur'}
        ],
      },
    }
  },
  mounted() {
    this.getDataFromServer();
  },
  methods: {
    //获取部门信息
    getDataFromServer() {
      this.$get("/oauth/dept/tree").then(res => {
        this.tableData = res.data.children;
        this.permission = res.data.children;
      })
    },
    add() {
      this.isEdit = false;
      this.formAction = {}
      this.action = '新增'
      this.dialogFormVisible = !this.dialogFormVisible;
    },
    handleEdit(pid, row) {
      //this.keys = [row.parentId]
      let b = Number(row.parentId) === 0 ? row.id : row.parentId;
      this.stdNode = [b];
      this.isEdit = true
      this.action = '编辑'

      this.formAction = {
        deptId: row.id,
        name: row.title,
        orderNum: row.order,
        parentId: row.parentId,
        isParent: row.hasChildren,
      }
      this.dialogFormVisible = !this.dialogFormVisible;
    },
    handleDelete(id) {
      this.$confirm('此操作将删除该数据，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$del("/oauth/dept/" + id).then(() => {
          this.$message.info("删除成功！");
          this.getDataFromServer();
        }).catch(() => {
          this.$message.info("删除失败！");
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }, //   部门单选
    handleClick(data, checked, node) {
      if (checked == true) {
        this.checkedId = data.id;
        this.$refs.treeForm.setCheckedKeys([data.id]);
      } else {
        if (this.checkedId == data.id) {
          this.$refs.treeForm.setCheckedKeys([data.id]);
        }
      }
    },
    nodeClick(data, checked, node) {
      this.checkedId = data.id
      this.$refs.treeForm.setCheckedKeys([data.id]);
    },
    submit(formAction) {
      this.$refs[formAction].validate((valid) => {
        if (valid) {
          if (this.action == '新增') {
            this.$post('/oauth/dept', {
              name: this.formAction.name,
              orderNum: this.formAction.orderNum,
              parentId: this.checkedId
            }).then(() => {
              this.$message.success("添加成功")
              this.dialogFormVisible = false
              this.getDataFromServer();
            }).catch(err => {
              this.$message.error(err.response.data.message)
            })
          } else if (this.action == '编辑') {
            this.formAction.parentId = this.checkedId
            this.$put('/oauth/dept', this.formAction).then(() => {
              this.$message.success("修改成功")
              this.dialogFormVisible = false
              this.getDataFromServer();
            }).catch(err => {
              this.$message.error(err.response.data.message)
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/exper/experiment";
</style>
